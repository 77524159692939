import React from 'react';
import ReactDOM from 'react-dom';
import { registerMicroApps, start } from 'qiankun';
import './index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('MainRoot')
);

const isDevelopment = process.env.NODE_ENV === 'development'
const getActiveRule = (hash: string) => {
  return (location: Location) => location.hash.startsWith(hash)
}

registerMicroApps([
  {
    name: 'Gouhuo',
    entry: isDevelopment 
      ? 'http://localhost:6011/' 
      : 'https://quwang-gouhuo.vercel.app/',
    container: '#SubContainer',
    activeRule: getActiveRule('#/gouhuo'),
  },
]);

start();