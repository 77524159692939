import { NavLink } from "react-router-dom";
import './index.scoped.scss'

const Header = () => (
  <div className="MainHeader">
    <div className="wrapper">
      <div className="conatiner">
        <div className="logo">
          <NavLink to="/">
            <img src={require("@/assets/images/logo.png").default} alt="趣往导航" />
          </NavLink>
        </div>
        
        <ul className="top-nav">
          <li><NavLink exact to="/">首页</NavLink></li>
          {/* <li><NavLink to="/gouhuo">篝火</NavLink></li> */}
        </ul>
      </div>
    </div>
  </div>
);

export default Header;
