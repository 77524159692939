import { Spin } from "antd"
import { FC, Suspense } from "react"
import './index.scoped.scss'

const Layout: FC = (props) => (
  <div className="MainLayout">
    <Suspense fallback={<div className="layout-loading"><Spin /></div>}>
      {props.children}
    </Suspense>
  </div>
)

export default Layout