import React, { FC, Suspense } from 'react';
import { HashRouter, Route, useLocation } from 'react-router-dom';
import Header from '@/components/Header';
const Home = React.lazy(() => import('./pages/Home'));
import MainLayout from './components/Layout';

const App: FC = () => (
  <div className="App">
    <HashRouter>
      <Header />

      <MainLayout>
        <Route path='/' exact component={Home}></Route>
        <div id="SubContainer"></div>
      </MainLayout>

    </HashRouter>
  </div>
);

export default App;
